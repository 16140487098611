
import React, { lazy, Suspense } from 'react'

export const route = [

    { path: '/customerList', Component: lazy(() => import('app/sale-agent/pagesMain/customer/list'))},
    { path: '/customer/:id', Component: lazy(() => import('app/sale-agent/pagesMain/customer/edit'))},

    // { path: '/taskList', Component: lazy(() => import('app/sale-agent/pagesMain/task/list'))},
    // { path: '/task/:id', Component: lazy(() => import('app/sale-agent/pagesMain/task/edit'))},

    // { path: '/taskStageList', Component: lazy(() => import('app/sale-agent/pagesMain/taskStage/list'))},
    // { path: '/taskStage/:id', Component: lazy(() => import('app/sale-agent/pagesMain/taskStage/edit'))},

    // { path: '/projectList', Component: lazy(() => import('app/sale-agent/pagesMain/project/list'))},
    // { path: '/project/:id', Component: lazy(() => import('app/sale-agent/pagesMain/project/edit'))},

    // { path: '/saleOrderList', Component: lazy(() => import('app/sale-agent/pagesMain/saleOrder/list'))},
    // { path: '/saleOrder/:id', Component: lazy(() => import('app/sale-agent/pagesMain/saleOrder/edit'))},

    // { path: '/saleOrderList', Component: lazy(() => import('app/sale-agent/pagesMain/saleOrder/list'))},
    // { path: '/saleOrder/:id', Component: lazy(() => import('app/sale-agent/pagesMain/saleOrder/edit'))},
    
]

export default route