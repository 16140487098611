const firebaseConfig = {
  apiKey: "AIzaSyClJTMC7g2LXYB8Tfizso-glRMekN9hVr8",
  authDomain: "sale-agent.firebaseapp.com",
  projectId: "sale-agent",
  storageBucket: "sale-agent.appspot.com",
  messagingSenderId: "218353752458",
  appId: "1:218353752458:web:32c8fc26ed727f6fe25073",
  measurementId: "G-WTEC7H4R65"
};
export default firebaseConfig 
