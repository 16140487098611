import React, { lazy, Suspense } from 'react'
import { route as menuDefaultRoute } from './menuDefault'
import { route as menuMainRoute } from './menuMain'

export const menuFront = [
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', icon: 'fe fe-phone-call', disabled: true, },
    { url: '/web/promotion', key: 'promotion', title: 'โปรโมชั่น', },
    { url: '/web/productGroupList', key: 'productGroupList', title: 'สินค้า', },
    { url: '/web/agent', key: 'agent', title: 'สนใจตัวแทน', },
    { url: '/web/contact', key: 'contact', title: 'ติดต่อเรา', },
    // { url: '/web/cart', key: 'cart', title: 'Cart', icon: 'fe fe-shopping-cart', },
    // { url: '/web/accountAddress', key: 'account', title: 'บัญชี', icon: 'fe fe-user', },
    // {
    //     title: 'บัญชีผู้ใช้',
    //     key: 'account',
    //     // icon: 'fe fe-user',
    //     // roles: ['admin'], 
    //     // count: 3,
    //     children: [
    //         { url: '/userMyProfile', key: 'accountProfile', title: 'ตั้งค่าบัญชี' },
    //         { url: '/web/accountAddress', key: 'accountAddress', title: 'ที่อยู่จัดส่ง' },
    //         { url: '/web/orderList', key: 'orderList', title: 'คำสั่งซื้อ' },
    //     ]
    // },
    // {
    //     title: 'Dashboards',
    //     key: 'dashboards',
    //     icon: 'fe fe-home',
    //     roles: ['admin'], 
    //     count: 8,
    //     children: [
    //     {
    //         title: 'Test',
    //         key: 'dashboardTest',
    //         url: '/dashboard/test',
    //     },
    //     ]
    // },
]

export const menuBackConfig = {
    menuBackground: {backgroundColor:"#222323"},
    menuColor1: {color:"#FFF"},
    menuColor2: {color:"#CCC"},
}
export const menuBack = {
    my: {
        title: '',
        roles: [ 'admin'],
        status: 'active',
        menu: [
            { sector: "sector1", title: "My" },
            { icon: "fe fe-file-text", to: "../userMyProfile", title: "Profile" },
            { icon: "fe fe-file-text", to: "../myCredit", title: "Credit / Point" },
            { icon: "fe fe-file-text", to: "../paymentList", title: "payment History" },
            { icon: "fe fe-file-text", to: "../mySaleProductSharingList", title: "Transaction" },
        ],
    },
    admin: {
        title: '',
        roles: [ 'admin'],
        status: 'active',
        menu: [
            { sector: "sectorUser", title: "Management" },
            { icon: "fe fe-users", to: "../userList", title: "User Management" },
            { icon: "fe fe-chevron-right", to: "../userPointList", title: "Member Credit/Point" },
            { icon: "fe fe-chevron-right", to: "../saleOrderList", title: "Sale Order" },
            { icon: "fe fe-chevron-right", to: "../saleProductList", title: "Profit Sharing" },
            { icon: "fe fe-chevron-right", to: "../saleProductSharingList", title: "Transaction Detail" },

            { sector: "sectorTask", title: "Task" },
            { icon: "fe fe-chevron-right", to: "../taskList", title: "task" },
            { icon: "fe fe-chevron-right", to: "../customerList", title: "customer" },
            { icon: "fe fe-chevron-right", to: "../taskStageList", title: "taskStage" },
            { icon: "fe fe-chevron-right", to: "../projectList", title: "project" },
            
            { sector: "sectorRegister", title: "Registered" },
            { icon: "fe fe-chevron-right", to: "../registerAgentList", title: "Agent Registered" },
            { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Registered" },

            { sector: "sectorHome", title: "Home Page Config" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Slide" },
            // { icon: "fe fe-chevron-right", to: "../homeContentLeftRightList", title: "Content Left Right" },
            // { icon: "fe fe-chevron-right", to: "../homeCustomerList", title: "Customer" },
            // { icon: "fe fe-chevron-right", to: "../homeCustomerReviewList", title: "Customer Review" },

            // { sector: "sectorWebConfig", title: "Web Config" },
            // { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            // { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            // { icon: "fe fe-chevron-right", to: "../productGroupList", title: "Product Group" },
            // { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            // { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            // { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            // { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },

        ],
    },
    dev: {
        title: 'Dev',
        roles: [ 'dev'],
        status: 'active',
        menu: [
            { icon: "fe fe-users", to: "../userList", title: "User Management" },
            { icon: "fe fe-chevron-right", to: "../goldSavingList", title: "Gold Saving" },
            { icon: "fe fe-chevron-right", to: "../testimonialList", title: "Testimonial" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },
            { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Register" },
            { icon: "fe fe-chevron-right", to: "../registerServiceList", title: "Register Service" },
            { icon: "fe fe-chevron-right", to: "../budgetList", title: "Budget" },
            { icon: "fe fe-chevron-right", to: "../serviceTypeList", title: "Service Type" },
            { icon: "fe fe-chevron-right", to: "../projectImageList", title: "Project Image" },
        ],
    },
}

export const route = [
    ...menuMainRoute,
    ...menuDefaultRoute,

    // auth
    { path: '/auth/login', Component: lazy(() => import('app/sale-agent/pages/auth/login'))},
    { path: '/auth/register', Component: lazy(() => import('app/sale-agent/pages/auth/register'))},


    
    { path: '/testList', Component: lazy(() => import('app/_custom/pages/test/list'))},
    { path: '/test/:id', Component: lazy(() => import('app/_custom/pages/test/edit'))},
  
    { path: '/testNewList', Component: lazy(() => import('app/_custom/pages/testNew/list'))},
    { path: '/testNewListEdit', Component: lazy(() => import('app/_custom/pages/testNew/listEdit'))},
    { path: '/testNew/:id', Component: lazy(() => import('app/_custom/pages/testNew/edit'))},
  
    { path: '/test3List', Component: lazy(() => import('app/_custom/pages/test3/list'))},
    { path: '/test3/:id', Component: lazy(() => import('app/_custom/pages/test3/edit'))},
    { path: '/test_normal', Component: lazy(() => import('app/_custom/pages/test_normal/list'))},
  
  
    // { path: '/contentList', Component: lazy(() => import('app/sale-agent/pages/content/list'))},
    { path: '/contentList', Component: lazy(() => import('app/sale-agent/pages/content/listCustom'))},
    // { path: '/content/:id', Component: lazy(() => import('app/sale-agent/pages/content/edit'))},
    { path: '/content/:id', Component: lazy(() => import('app/sale-agent/pages/content/editCustom'))},
  
    // { path: '/campaignList', Component: lazy(() => import('app/sale-agent/pages/campaign/list'))},
    { path: '/campaignList', Component: lazy(() => import('app/sale-agent/pages/campaign/listCustom'))},
    // { path: '/campaign/:id', Component: lazy(() => import('app/sale-agent/pages/campaign/edit'))},
    { path: '/campaign/:id', Component: lazy(() => import('app/sale-agent/pages/campaign/editCustom'))},
    { path: '/campaignView/:id', Component: lazy(() => import('app/sale-agent/pages/campaign/showCustom'))},
  
    { path: '/influencerList', Component: lazy(() => import('app/sale-agent/pages/influencer/list'))},
    { path: '/influencer/:id', Component: lazy(() => import('app/sale-agent/pages/influencer/edit'))},
  
    { path: '/customerList', Component: lazy(() => import('app/sale-agent/pages/customer/list'))},
    { path: '/customer/:id', Component: lazy(() => import('app/sale-agent/pages/customer/edit'))},
  
    { path: '/contentTypeList', Component: lazy(() => import('app/sale-agent/pages/contentType/list'))},
    { path: '/contentType/:id', Component: lazy(() => import('app/sale-agent/pages/contentType/edit'))},
  
  
    
    // Custom
    { path: '/userMyProfile', Component: lazy(() => import('app/_custom/pages/userMyProfile')), exact: true,},
  
  
    // sale-agent PROJECT ///////////////////////////////////////////////////////////////
    // my
    { path: '/myCredit', Component: lazy(() => import('app/sale-agent/pages/my/myCredit'))},
    
    { path: '/paymentList', Component: lazy(() => import('app/sale-agent/pages/payment/list'))},
    { path: '/payment/:id', Component: lazy(() => import('app/sale-agent/pages/payment/edit'))},
  
  
    // user
    { path: '/userList', Component: lazy(() => import('app/sale-agent/pages/user/list'))},
    { path: '/user/:id', Component: lazy(() => import('app/sale-agent/pages/user/edit'))},

    { path: '/userViewOrganization/:id', Component: lazy(() => import('app/sale-agent/pages/user/viewOrganization'))},
    // user
    { path: '/userPointList', Component: lazy(() => import('app/sale-agent/pages/userPoint/list'))},
    { path: '/userPoint/:id', Component: lazy(() => import('app/sale-agent/pages/userPoint/edit'))},
  
    // productGroup
    { path: '/productGroupList', Component: lazy(() => import('app/sale-agent/pages/productGroup/list'))},
    { path: '/productGroup/:id', Component: lazy(() => import('app/sale-agent/pages/productGroup/edit'))},
    // saleOrder
    { path: '/saleOrder/:id', Component: lazy(() => import('app/sale-agent/pages/saleOrder/edit'))},
    { path: '/saleOrderList',     Component: lazy(() => import('app/sale-agent/pages/saleOrder/list'))},
    // saleProduct
    { path: '/saleProductList/:id', Component: lazy(() => import('app/sale-agent/pages/saleProduct/list'))},
    { path: '/saleProductList',     Component: lazy(() => import('app/sale-agent/pages/saleProduct/list'))},

    // saleOrder
    { path: '/order/:id', Component: lazy(() => import('app/sale-agent/pages/order/edit'))},
    { path: '/orderList',     Component: lazy(() => import('app/sale-agent/pages/order/list'))},
  
    { path: '/saleProduct/:id', Component: lazy(() => import('app/sale-agent/pages/saleProduct/edit'))},
    { path: '/saleProductView/:id', Component: lazy(() => import('app/sale-agent/pages/saleProduct/view'))},
    // saleProductSharing
    { path: '/saleProductSharingList/:transaction_id', Component: lazy(() => import('app/sale-agent/pages/saleProductSharing/list'))},
    { path: '/saleProductSharingList', Component: lazy(() => import('app/sale-agent/pages/saleProductSharing/list'))},
    { path: '/saleProductSharing/:id', Component: lazy(() => import('app/sale-agent/pages/saleProductSharing/edit'))},
    { path: '/mySaleProductSharingList', Component: lazy(() => import('app/sale-agent/pages/saleProductSharing/myList'))},
  
  
  
    { path: '/web/thankYou', Component: lazy(() => import('app/sale-agent/pages/web/thankYou'))},
    { path: '/web/privacy', Component: lazy(() => import('app/sale-agent/pages/web/privacy'))},
    // home
    { path: '/web/video/:id', Component: lazy(() => import('app/sale-agent/pages/web/videoDetail'))},
    { path: '/web/promotion/:id', Component: lazy(() => import('app/sale-agent/pages/web/promotionDetail'))},
  
  
    { path: '/web/account', Component: lazy(() => import('app/sale-agent/pages/web/account'))},
    { path: '/web/accountProfile', Component: lazy(() => import('app/sale-agent/pages/web/accountProfile'))},
    { path: '/web/accountAddress', Component: lazy(() => import('app/sale-agent/pages/web/accountAddress'))},
    { path: '/web/home', Component: lazy(() => import('app/sale-agent/pages/web/home'))},
    { path: '/web/homeRegister', Component: lazy(() => import('app/sale-agent/pages/web/homeRegister'))},
    { path: '/web/about', Component: lazy(() => import('app/sale-agent/pages/web/about'))},
    { path: '/web/contact', Component: lazy(() => import('app/sale-agent/pages/web/contact'))},
    { path: '/web/promotion', Component: lazy(() => import('app/sale-agent/pages/web/promotion'))},
    { path: '/web/salePromotion', Component: lazy(() => import('app/sale-agent/pages/web/salePromotion'))},
    { path: '/web/servicePromotion', Component: lazy(() => import('app/sale-agent/pages/web/servicePromotion'))},
    { path: '/web/video', Component: lazy(() => import('app/sale-agent/pages/web/video'))},
    { path: '/web/product/:id', Component: lazy(() => import('app/sale-agent/pages/web/product'))},
    { path: '/web/productList/:id', Component: lazy(() => import('app/sale-agent/pages/web/productList'))},
    { path: '/web/productGroupList', Component: lazy(() => import('app/sale-agent/pages/web/productGroupList'))},
    { path: '/web/savingGold', Component: lazy(() => import('app/sale-agent/pages/web/savingGold'))},
  
    { path: '/web/blog', Component: lazy(() => import('app/sale-agent/pages/web/blog'))},
    { path: '/web/agent', Component: lazy(() => import('app/sale-agent/pages/web/agent'))},
    { path: '/web/cart', Component: lazy(() => import('app/sale-agent/pages/web/cart'))},
    { path: '/web/checkout', Component: lazy(() => import('app/sale-agent/pages/web/checkout'))},
    { path: '/web/orderList', Component: lazy(() => import('app/sale-agent/pages/web/orderList'))},
    { path: '/web/order/:id', Component: lazy(() => import('app/sale-agent/pages/web/order'))},
    { path: '/web/orderPayment', Component: lazy(() => import('app/sale-agent/pages/web/orderPayment'))},
  
    
  
  
    // admin
    { path: '/blogList', Component: lazy(() => import('app/sale-agent/pages/blog/list'))},
    { path: '/blog/:id', Component: lazy(() => import('app/sale-agent/pages/blog/edit'))},
  
    { path: '/blogGroupList', Component: lazy(() => import('app/sale-agent/pages/blogGroup/list'))},
    { path: '/blogGroup/:id', Component: lazy(() => import('app/sale-agent/pages/blogGroup/edit'))},
  
    // { path: '/homeSlideList', Component: lazy(() => import('app/sale-agent/pages/homeSlide/list'))},
    // { path: '/homeSlide/:id', Component: lazy(() => import('app/sale-agent/pages/homeSlide/edit'))},
  
    { path: '/productList', Component: lazy(() => import('app/sale-agent/pages/product/list'))},
    { path: '/product/:id', Component: lazy(() => import('app/sale-agent/pages/product/edit'))},
  
    { path: '/productGroupList', Component: lazy(() => import('app/sale-agent/pages/productGroup/list'))},
    { path: '/productGroup/:id', Component: lazy(() => import('app/sale-agent/pages/productGroup/edit'))},
  
    { path: '/videoList', Component: lazy(() => import('app/sale-agent/pages/video/list'))},
    { path: '/video/:id', Component: lazy(() => import('app/sale-agent/pages/video/edit'))},
  
    { path: '/videoGroupList', Component: lazy(() => import('app/sale-agent/pages/videoGroup/list'))},
    { path: '/videoGroup/:id', Component: lazy(() => import('app/sale-agent/pages/videoGroup/edit'))},
  
    { path: '/promotionList', Component: lazy(() => import('app/sale-agent/pages/promotion/list'))},
    { path: '/promotion/:id', Component: lazy(() => import('app/sale-agent/pages/promotion/edit'))},
  
    { path: '/promotionGroupList', Component: lazy(() => import('app/sale-agent/pages/promotionGroup/list'))},
    { path: '/promotionGroup/:id', Component: lazy(() => import('app/sale-agent/pages/promotionGroup/edit'))},
  
    { path: '/registerAgentList', Component: lazy(() => import('app/sale-agent/pages/registerAgent/list'))},
    { path: '/registerAgent/:id', Component: lazy(() => import('app/sale-agent/pages/registerAgent/edit'))},

    { path: '/registerLeadList', Component: lazy(() => import('app/sale-agent/pages/registerLead/list'))},
    { path: '/registerLead/:id', Component: lazy(() => import('app/sale-agent/pages/registerLead/edit'))},
  
    { path: '/registerServiceList', Component: lazy(() => import('app/sale-agent/pages/registerService/list'))},
    { path: '/registerService/:id', Component: lazy(() => import('app/sale-agent/pages/registerService/edit'))},
  
    { path: '/budgetList', Component: lazy(() => import('app/sale-agent/pages/budget/list'))},
    { path: '/budget/:id', Component: lazy(() => import('app/sale-agent/pages/budget/edit'))},
  
    { path: '/serviceTypeList', Component: lazy(() => import('app/sale-agent/pages/serviceType/list'))},
    { path: '/serviceType/:id', Component: lazy(() => import('app/sale-agent/pages/serviceType/edit'))},
  
    { path: '/projectImageList', Component: lazy(() => import('app/sale-agent/pages/projectImage/list'))},
    { path: '/projectImage/:id', Component: lazy(() => import('app/sale-agent/pages/projectImage/edit'))},
  
  
  
  
    { path: '/testimonialList', Component: lazy(() => import('app/sale-agent/pages/testimonial/list'))},
    { path: '/testimonial/:id', Component: lazy(() => import('app/sale-agent/pages/testimonial/edit'))},
  
    { path: '/goldSavingList', Component: lazy(() => import('app/sale-agent/pages/goldSaving/list'))},
    { path: '/goldSaving/:id', Component: lazy(() => import('app/sale-agent/pages/goldSaving/edit'))},
  
  
  
  
    // admin
    { path: '/blogList', Component: lazy(() => import('app/gentlestots/pages/blog/list'))},
    { path: '/blog/:id', Component: lazy(() => import('app/gentlestots/pages/blog/edit'))},
  
    { path: '/blogGroupList', Component: lazy(() => import('app/gentlestots/pages/blogGroup/list'))},
    { path: '/blogGroup/:id', Component: lazy(() => import('app/gentlestots/pages/blogGroup/edit'))},
  
    { path: '/contactRegisterList', Component: lazy(() => import('app/gentlestots/pages/contactRegister/list'))},
    { path: '/contactRegister/:id', Component: lazy(() => import('app/gentlestots/pages/contactRegister/edit'))},
  
]