import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Image, Menu, Dropdown, Avatar, Badge, Tooltip } from 'antd'
import { getList } from 'config/dbUtils'
import styles from './style.module.scss'

const mapStateToProps = ({ dispatch, user, settings }) => ({ dispatch, user, settings })

const ProfileMenu = ({ dispatch, user, settings }) => {
  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <a href="#/web/cart">
          <i className={`${styles.menuIcon} fas fa-shopping-cart`} />
          ตะกร้าสินค้า
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="#/web/orderList">
          <i className={`${styles.menuIcon} fas fa-order`} />
          รายการสั่งซื้อ
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#/web/accountAddress">
          <i className={`${styles.menuIcon} fas fa-address`} />
          ที่อยู่จัดส่ง
        </a>
      </Menu.Item>
    </Menu>
  )

  const docName = 'cart'
  const [pageSize, setPageSize] = React.useState(200)
  const [first, setFirst] = React.useState(null)
  const [last, setLast] = React.useState(null)
  const page = { pageSize, setPageSize, first, setFirst, last, setLast }

  const [docList, setDocList] = React.useState([]);
  const docParam = { docName: docName, docList: docList, setDocList: setDocList,
    subDoc: { docId: user.id, collectionName: 'product'}
  }

  const search = (action, whereListSearch, orderListSearch) =>{
    const whereList = []
    getList({...page, action: action}, null, docParam, whereList, [])
  }
  const fetchData = async () => { 
    search("search")
  }
  const [loaded, setLoaded] = React.useState(false)
  if(user.id && loaded === false) {
    // console.log('loadData')
    setLoaded(true)
    fetchData()
  }

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Badge count={docList.reduce((a, b) => a + parseInt(b.amount, 10), 0)}>
          <i className={`fe fe-shopping-cart font-size-28`}></i>
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
