import React, { lazy, Suspense } from 'react'

export const menuFront = [
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', icon: 'fe fe-phone-call', disabled: true, },
    { url: '/web/promotion', key: 'promotion', title: 'โปรโมชั่น', },
    { url: '/web/productGroupList', key: 'productGroupList', title: 'สินค้า', },
    { url: '/web/savingGold', key: 'savingGold', title: 'ออมทองกับเรา', },
    { url: '/web/contact', key: 'contact', title: 'ติดต่อเรา', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // {
    //     title: 'Dashboards',
    //     key: 'dashboards',
    //     icon: 'fe fe-home',
    //     roles: ['admin'], 
    //     count: 8,
    //     children: [
    //     {
    //         title: 'Test',
    //         key: 'dashboardTest',
    //         url: '/dashboard/test',
    //     },
    //     ]
    // },
]

export const menuBackConfig = {
    menuBackground: {backgroundColor:"#222323"},
    menuColor1: {color:"#FFF"},
    menuColor2: {color:"#CCC"},
}
export const menuBack = {
    my: {
        title: 'My Profile',
        roles: [ 'admin'],
        status: 'active',
        menu: [
            { icon: "fe fe-chevron-right", to: "../userMyProfile", title: "My Profile" },
            { icon: "fe fe-chevron-right", to: "../myGold", title: "My Gold / Point" },
            { icon: "fe fe-chevron-right", to: "../paymentList", title: "Gold Saving Payment1" },
            // { icon: "fe fe-chevron-right", to: "../ybkkList", title: "Lead / Sales Page" },
            // { icon: "fe fe-chevron-right", to: "../ybkkOrderList", title: "รายการขาย" },
            // { icon: "fe fe-chevron-right", to: "../leadPageOrderList", title: "ลูกค้าลงทะเบียน" },
            // { icon: "fe fe-chevron-right", to: "../ybkkRegisterList", title: "ลูกค้าลงทะเบียน" },
            // { icon: "fe fe-chevron-right", to: "../myCredit", title: "My Credit / Point" },
            // { icon: "fe fe-chevron-right", to: "../mySaleProductSharingList", title: "Credit / Point Tran.." },
        ],
    },
    dev: {
        title: 'Dev',
        roles: [ 'dev'],
        status: 'active',
        menu: [
            { icon: "fe fe-users", to: "../userList", title: "User Management" },
            { icon: "fe fe-chevron-right", to: "../goldSavingList", title: "Gold Saving" },
            { icon: "fe fe-chevron-right", to: "../testimonialList", title: "Testimonial" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },
            { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Register" },
            { icon: "fe fe-chevron-right", to: "../registerServiceList", title: "Register Service" },
            { icon: "fe fe-chevron-right", to: "../budgetList", title: "Budget" },
            { icon: "fe fe-chevron-right", to: "../serviceTypeList", title: "Service Type" },
            { icon: "fe fe-chevron-right", to: "../projectImageList", title: "Project Image" },
        ],
    },
    office: {
        title: 'Office',
        roles: [ 'admin'],
        status: 'inActive',
        menu: [
            { icon: "fe fe-chevron-right", to: "../userPointList", title: "Member Credit/Point" },
            { icon: "fe fe-chevron-right", to: "../saleProductList", title: "Member (Profit Sharing)" },
            { icon: "fe fe-chevron-right", to: "../saleProductSharingList", title: "Credit / Point Tran.." },
            // { icon: "fe fe-chevron-right", to: "../", title: "" },
            // { icon: "fe fe-chevron-right", to: "../", title: "" },
        ],
    },
    admin: {
        title: 'Admin',
        roles: [ 'admin'],
        status: 'active',
        menu: [
            // { icon: "fe fe-chevron-right", to: "../campaignList", title: "Campaign" },
            // { icon: "fe fe-chevron-right", to: "../contentList", title: "Content" },
            // { icon: "fe fe-chevron-right", to: "../customerList", title: "Customer" },
            // { icon: "fe fe-chevron-right", to: "../influencerList", title: "Influencer" },
            { icon: "fe fe-users", to: "../userList", title: "User Management" },
            // { icon: "fe fe-chevron-right", to: "../contentTypeList", title: "Content Type" },

            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../productGroupList", title: "ProductGroup" },
            // { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            // { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            // { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },
            // { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Register" },
            // { icon: "fe fe-chevron-right", to: "../registerServiceList", title: "Register Service" },
            // { icon: "fe fe-chevron-right", to: "../budgetList", title: "Budget" },
            // { icon: "fe fe-chevron-right", to: "../serviceTypeList", title: "Service Type" },
            // { icon: "fe fe-chevron-right", to: "../projectImageList", title: "Project Image" },

            { icon: "fe fe-chevron-right", to: "../userPointList", title: "Member Credit/Point" },
            { icon: "fe fe-chevron-right", to: "../saleProductList", title: "Member Payment" },
            { icon: "fe fe-chevron-right", to: "../saleProductSharingList", title: "Transaction" },
        ],
    },
}

export const route = [
    // home page config
    { path: '/homeSlideList', Component: lazy(() => import('app/_custom/pages/homeSlide/list'))},
    { path: '/homeSlide/:id', Component: lazy(() => import('app/_custom/pages/homeSlide/edit'))},

    { path: '/homeContentLeftRightList', Component: lazy(() => import('app/_custom/pages/homeContentLeftRight/list'))},
    { path: '/homeContentLeftRight/:id', Component: lazy(() => import('app/_custom/pages/homeContentLeftRight/edit'))},

    { path: '/homeCustomerList', Component: lazy(() => import('app/_custom/pages/homeCustomer/list'))},
    { path: '/homeCustomer/:id', Component: lazy(() => import('app/_custom/pages/homeCustomer/edit'))},

    { path: '/homeCustomerReviewList', Component: lazy(() => import('app/_custom/pages/homeCustomerReview/list'))},
    { path: '/homeCustomerReview/:id', Component: lazy(() => import('app/_custom/pages/homeCustomerReview/edit'))},

    { path: '/projectImageList', Component: lazy(() => import('app/_custom/pages/projectImage/list'))},
    { path: '/projectImage/:id', Component: lazy(() => import('app/_custom/pages/projectImage/edit'))},

    // home page config

    { path: '/productList', Component: lazy(() => import('app/_custom/pages/product/list'))},
    { path: '/product/:id', Component: lazy(() => import('app/_custom/pages/product/edit'))},

    { path: '/productGroupList', Component: lazy(() => import('app/_custom/pages/productGroup/list'))},
    { path: '/productGroup/:id', Component: lazy(() => import('app/_custom/pages/productGroup/edit'))},
  
    { path: '/videoList', Component: lazy(() => import('app/_custom/pages/video/list'))},
    { path: '/video/:id', Component: lazy(() => import('app/_custom/pages/video/edit'))},
  
    { path: '/videoGroupList', Component: lazy(() => import('app/_custom/pages/videoGroup/list'))},
    { path: '/videoGroup/:id', Component: lazy(() => import('app/_custom/pages/videoGroup/edit'))},
  
    { path: '/promotionList', Component: lazy(() => import('app/_custom/pages/promotion/list'))},
    { path: '/promotion/:id', Component: lazy(() => import('app/_custom/pages/promotion/edit'))},
  
    { path: '/promotionGroupList', Component: lazy(() => import('app/_custom/pages/promotionGroup/list'))},
    { path: '/promotionGroup/:id', Component: lazy(() => import('app/_custom/pages/promotionGroup/edit'))},
  
    { path: '/registerLeadList', Component: lazy(() => import('app/_custom/pages/registerLead/list'))},
    { path: '/registerLead/:id', Component: lazy(() => import('app/_custom/pages/registerLead/edit'))},

    { path: '/rewardList', Component: lazy(() => import('app/_custom/pages/reward/list'))},
    { path: '/reward/:id', Component: lazy(() => import('app/_custom/pages/reward/edit'))},

    { path: '/rewardGroupList', Component: lazy(() => import('app/_custom/pages/rewardGroup/list'))},
    { path: '/rewardGroup/:id', Component: lazy(() => import('app/_custom/pages/rewardGroup/edit'))},

    { path: '/memberReceivedRewardList', Component: lazy(() => import('app/_custom/pages/memberReceivedReward/listCustom'))},
    { path: '/memberReceivedReward/:id', Component: lazy(() => import('app/_custom/pages/memberReceivedReward/editCustom'))},

    { path: '/memberReceivedRewardReportList', Component: lazy(() => import('app/_custom/pages/memberReceivedRewardReport/listCustom'))},


    { path: '/editorList', Component: lazy(() => import('app/_custom/pages/editor/list'))},
    { path: '/editorList', Component: lazy(() => import('app/_custom/pages/editor/list'))},
    { path: '/editor/:id', Component: lazy(() => import('app/_custom/pages/editor/edit'))},
  
    // { path: '/editorList', Component: lazy(() => 
    //   fs.existsSync('/src/app/_custom/pages/editor/list')
    //   .catch(() => ({ default: () => import('app/_custom/pages/editor/list') }))
    // )},
    
    { path: '/testList', Component: lazy(() => import('app/_custom/pages/test/list'))},
    { path: '/test/:id', Component: lazy(() => import('app/_custom/pages/test/edit'))},
  
    { path: '/testNewList', Component: lazy(() => import('app/_custom/pages/testNew/list'))},
    { path: '/testNewListEdit', Component: lazy(() => import('app/_custom/pages/testNew/listEdit'))},
    { path: '/testNew/:id', Component: lazy(() => import('app/_custom/pages/testNew/edit'))},
  
    { path: '/test3List', Component: lazy(() => import('app/_custom/pages/test3/list'))},
    { path: '/test3/:id', Component: lazy(() => import('app/_custom/pages/test3/edit'))},
    { path: '/test_normal', Component: lazy(() => import('app/_custom/pages/test_normal/list'))},

    { path: '/testUploadList', Component: lazy(() => import('app/_custom/pages/testUpload/list'))},
    { path: '/testUpload/:id', Component: lazy(() => import('app/_custom/pages/testUpload/edit'))},
  
    // { path: '/test_full', Component: lazy(() => import('app/_custom/pages/test_full/list'))},
    // dynamic
    { path: '/devInit', Component: lazy(() => import('app/_custom/pages/zystem/devInit'))},
    { path: '/masterList', Component: lazy(() => import('app/_custom/pages/master/list'))},
    { path: '/master/:id', Component: lazy(() => import('app/_custom/pages/master/edit'))},
    // user
    // { path: '/userList', Component: lazy(() => import('app/_custom/pages/user/list'))},
    // { path: '/user/:id', Component: lazy(() => import('app/_custom/pages/user/edit'))},
    { path: '/userNameCardList', Component: lazy(() => import('app/_custom/pages/user/listWithNameCard'))},
    { path: '/downloadNameCard/:id', Component: lazy(() => import('app/_custom/pages/user/downloadNameCard'))},
    { path: '/web/downloadNameCard/:id', Component: lazy(() => import('app/_custom/pages/user/downloadNameCard'))},
    { path: '/web/nameCard/:id', Component: lazy(() => import('app/_custom/pages/user/nameCard'))},
    
    
    { path: '/userNotFoundList', Component: lazy(() => import('app/_custom/pages/userNotFound/list'))},
    { path: '/userNotFound/:id', Component: lazy(() => import('app/_custom/pages/userNotFound/edit'))},

    { path: '/web/loadProfile', Component: lazy(() => import('app/_custom/pages/user/loadProfile'))},
    // Custom
    // { path: '/userMyProfile', Component: lazy(() => import('app/_custom/pages/userMyProfile')), exact: true,},
  
    // ybkk PROJECT ///////////////////////////////////////////////////////////////
    // my
  
]