
import React, { lazy, Suspense } from 'react'

export const route = [
{ path: '/customerList', Component: lazy(() => import('app/sale-agent/pagesDefault/customer/list'))},
{ path: '/customer/:id', Component: lazy(() => import('app/sale-agent/pagesDefault/customer/edit'))},

{ path: '/projectList', Component: lazy(() => import('app/sale-agent/pagesDefault/project/list'))},
{ path: '/project/:id', Component: lazy(() => import('app/sale-agent/pagesDefault/project/edit'))},

{ path: '/taskList', Component: lazy(() => import('app/sale-agent/pagesDefault/task/list'))},
{ path: '/task/:id', Component: lazy(() => import('app/sale-agent/pagesDefault/task/edit'))},

{ path: '/taskStageList', Component: lazy(() => import('app/sale-agent/pagesDefault/taskStage/list'))},
{ path: '/taskStage/:id', Component: lazy(() => import('app/sale-agent/pagesDefault/taskStage/edit'))},

]

export default route